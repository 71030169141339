'use client';

import { Icon } from '@components/Icon';
import { useCheckBenefit } from '@hooks/useCheckBenefit';
import { useOutsideClick } from '@hooks/useOutsideClick';
import useDetectScroll from '@smakss/react-scroll-direction';
import { sendGAEvent } from '@utils/ga';
import clsx from 'clsx';
import { language } from 'i18n';
import { useTranslation } from 'i18n/client';
import Image from 'next/image';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import { useEffect, useRef, useState } from 'react';
import { Button } from '../button';
import { LanguageSelector } from '../languageSelector';
import { HeaderExternalLink } from './externalLink';
import { HeaderLink } from './link';
import { HeaderPageTitle } from './pageTitle';
import styles from './styles.module.scss';

export const HEADER_HEIGHT = 118;

interface HeaderProps {
  lng: language;
}

export function Header({ lng }: HeaderProps) {
  const pathname = usePathname();

  const { t } = useTranslation(lng, 'header');

  const { height: checkBenefitBannerHeight, handleOpenCheckBenefitAccordion } =
    useCheckBenefit();

  const { scrollDir, scrollPosition } = useDetectScroll();

  const [isMenuActive, setIsMenuActive] = useState(false);

  const menuRef = useRef<HTMLDivElement>(null);

  useOutsideClick({ ref: menuRef, callback: () => setIsMenuActive(false) });

  const MENU_ITEMS = [
    {
      label: t('skeelo'),
      href: 'https://skeelo.com/',
    },
    {
      label: t('skeelo_store'),
      href: 'https://loja.skeelo.com/',
    },
    {
      label: t('skoob'),
      href: 'https://www.skoob.com.br/',
    },
  ];

  const INTERNAL_LINKS = [
    {
      label: t('home'),
      href: '/',
    },
    // {
    //   label: t('for_readers'),
    //   href: '/para-leitores',
    //   onClick: () => sendGAEvent('productfeatures_page_header_click'),
    // },
    {
      label: t('for_companies'),
      href: 'https://parceiro.skeelo.com/empresas?utm_source=site&utm_medium=cpc&utm_campaign=sva_provedor',
      onClick: () => sendGAEvent('b2b_page_header_click'),
    },
    // {
    //   label: t('new_reading'),
    //   href: '/nova-leitura',
    // },
    {
      label: t('blog'),
      href: 'https://blog.skeelo.com/',
    },
  ];

  const cleanPathname = pathname?.replace(/\/(pt|es|en)/, '') ?? '';

  const shouldShowFullHeader = lng === 'pt';

  const firstMenuItem = MENU_ITEMS[0];
  const allMenuItemsExceptFirst = MENU_ITEMS.slice(1);

  const isDesktopMenuClosed =
    scrollDir === 'down' &&
    scrollPosition.top > checkBenefitBannerHeight + HEADER_HEIGHT;

  const handleCheckBenefit = () => {
    setIsMenuActive(false);

    handleOpenCheckBenefitAccordion('checkbenefit_header_click');
  };

  useEffect(() => {
    if (isMenuActive) {
      document.body.style.overflowY = 'hidden';
    } else {
      document.body.style.overflowY = '';
    }
  }, [isMenuActive]);

  return (
    <header
      className={clsx(
        'z-40 w-full max-w-[100vw] bg-text-white-positive',
        styles.header,
        (isDesktopMenuClosed || !shouldShowFullHeader) && styles.withBorder
      )}
    >
      <div
        className={clsx('z-50', styles.backdrop, isMenuActive && styles.active)}
      />

      <div
        className={clsx(
          'relative z-40 mx-auto flex w-full max-w-[1360px] items-center justify-between bg-text-white-positive lg:p-4',
          shouldShowFullHeader
            ? 'py-2 pl-5 pr-3 md:pl-[22px] md:pr-[14px]'
            : 'px-3 py-5 md:px-6 md:py-4'
        )}
      >
        <div className="flex items-center gap-10">
          <Link
            href="/"
            className="max-w-[29px] transition-opacity hover:opacity-70 md:max-w-[97px]"
          >
            <Image
              width={29}
              height={32}
              alt="Skeelo"
              className="block w-full md:hidden"
              src="/img/logos/skeelo/img-logo.svg"
            />
            <Image
              width={97}
              height={32}
              alt="Skeelo"
              className="hidden w-full md:block"
              src="/img/logos/skeelo/default-logo.svg"
            />
          </Link>

          {shouldShowFullHeader && (
            <nav className="hidden items-center gap-5 lg:flex">
              {MENU_ITEMS.map((item, index) => (
                <HeaderExternalLink
                  href={item.href}
                  isActive={index === 0}
                  key={`${item.href}-${index}`}
                >
                  {item.label}
                </HeaderExternalLink>
              ))}
            </nav>
          )}
        </div>

        {shouldShowFullHeader ? (
          <>
            <HeaderPageTitle lng={lng} cleanPathname={cleanPathname ?? ''} />

            <button
              type="button"
              title={t('open_menu')}
              aria-label={t('open_menu')}
              onClick={() => setIsMenuActive(true)}
              className="block select-none px-4 py-3 text-text-500 outline-none lg:hidden"
            >
              <Icon.Menu2 />
            </button>
          </>
        ) : (
          <></>
        )}

        <div
          className={clsx(
            'relative z-[60] items-center gap-5',
            shouldShowFullHeader ? 'hidden lg:flex' : 'flex'
          )}
        >
          <Button
            onClick={handleCheckBenefit}
            className={clsx(
              'px-5 py-[10px] text-body-micro',
              !shouldShowFullHeader && 'hidden md:block'
            )}
          >
            {t('check_benefit')}
          </Button>

          <LanguageSelector lng={lng} />
        </div>
      </div>

      {shouldShowFullHeader && (
        <div
          ref={menuRef}
          className={clsx(
            'absolute right-0 top-0 z-[60] flex w-full max-w-[258px] flex-col justify-between p-5 lg:relative lg:z-0 lg:max-w-full lg:bg-text-100 lg:p-0',
            styles.menu,
            isMenuActive && styles.active,
            isDesktopMenuClosed && styles.closed
          )}
        >
          <div className="mt-3 lg:mt-0">
            <button
              type="button"
              title={t('close_menu')}
              aria-label={t('close_menu')}
              onClick={() => setIsMenuActive(false)}
              className="absolute right-3 top-3 block h-6 w-6 select-none text-text-300 outline-none lg:hidden"
            >
              <Icon.Close2 />
            </button>

            <div className="flex flex-col items-start gap-5 px-5 lg:hidden">
              <HeaderExternalLink isActive href={firstMenuItem.href}>
                {firstMenuItem.label}
              </HeaderExternalLink>

              <Button
                className="px-5 py-[10px] text-left text-body-micro"
                onClick={handleCheckBenefit}
              >
                {t('check_benefit')}
              </Button>
            </div>

            <nav className="mx-auto flex w-full max-w-[1360px] flex-col overflow-hidden px-5 pt-3 lg:flex-row lg:items-center lg:gap-6 lg:px-4 lg:pt-2">
              {INTERNAL_LINKS.map((item, index) => (
                <HeaderLink
                  href={item.href}
                  key={`${item.href}-${index}`}
                  isActive={item.href === cleanPathname}
                  onClick={item.onClick}
                >
                  {item.label}
                </HeaderLink>
              ))}
            </nav>

            <div className="mt-5 flex flex-col items-start gap-5 px-5 lg:hidden">
              {allMenuItemsExceptFirst.map((item, index) => (
                <HeaderExternalLink
                  key={`${item.href}-${index}`}
                  href={item.href}
                >
                  {item.label}
                </HeaderExternalLink>
              ))}
            </div>
          </div>

          <div className="mb-3 flex justify-end lg:hidden">
            <LanguageSelector lng={lng} />
          </div>
        </div>
      )}
    </header>
  );
}
